import React from 'react';

const GooglePlayIconDark = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="149"
    height="44"
    fill="none"
    viewBox="0 0 149 44"
    {...props}
  >
    <g clipPath="url(#clip0_296_11970)">
      <path
        fill="#fff"
        d="M143 0H5.5A5.5 5.5 0 000 5.5v33A5.5 5.5 0 005.5 44H143a5.5 5.5 0 005.5-5.5v-33A5.5 5.5 0 00143 0z"
      ></path>
      <path
        fill="#000"
        d="M143 .88a4.622 4.622 0 014.62 4.62v33a4.621 4.621 0 01-4.62 4.62H5.5A4.62 4.62 0 01.88 38.5v-33A4.62 4.62 0 015.5.88H143zm0-.88H5.5A5.5 5.5 0 000 5.5v33A5.5 5.5 0 005.5 44H143a5.499 5.499 0 005.5-5.5v-33A5.498 5.498 0 00143 0z"
      ></path>
      <path
        fill="#000"
        stroke="#000"
        strokeMiterlimit="10"
        strokeWidth="0.411"
        d="M52.162 11.273a2.995 2.995 0 01-.825 2.2 3.189 3.189 0 01-2.42.967 3.3 3.3 0 01-2.43-.99 3.509 3.509 0 010-4.905 3.476 3.476 0 013.783-.715c.397.163.75.415 1.034.737l-.583.583a2.2 2.2 0 00-1.804-.792 2.64 2.64 0 101.848 4.542c.336-.368.531-.843.55-1.341h-2.398v-.792h3.201c.025.167.04.336.044.506zM57.2 8.51h-2.97v2.09h2.706v.791H54.23v2.09h2.97v.814h-3.828v-6.6H57.2v.814zM60.808 14.295h-.847V8.51h-1.848v-.814H62.7v.814h-1.892v5.786zM65.934 14.295v-6.6h.847v6.6h-.847zM70.543 14.295h-.847V8.51h-1.848v-.814h4.532v.814h-1.837v5.786zM80.97 13.44a3.42 3.42 0 01-4.84 0 3.564 3.564 0 010-4.883 3.223 3.223 0 012.42-1.001 3.3 3.3 0 012.42 1A3.378 3.378 0 0181.94 11a3.301 3.301 0 01-.968 2.442zm-4.212-.55a2.52 2.52 0 003.586 0 2.794 2.794 0 000-3.783 2.519 2.519 0 00-3.586 0 2.794 2.794 0 000 3.784zM83.138 14.295v-6.6h1.023l3.212 5.137V7.696h.847v6.6h-.88l-3.355-5.38v5.379h-.847z"
      ></path>
      <path
        fill="#000"
        d="M74.954 23.925a4.686 4.686 0 104.697 4.675 4.61 4.61 0 00-4.697-4.675zm0 7.514a2.839 2.839 0 112.64-2.838 2.707 2.707 0 01-2.64 2.838zm-10.252-7.514a4.686 4.686 0 104.697 4.675 4.61 4.61 0 00-4.697-4.675zm0 7.514a2.839 2.839 0 112.64-2.838 2.707 2.707 0 01-2.64 2.838zm-12.188-6.073v1.98h4.752a4.147 4.147 0 01-1.1 2.497 4.861 4.861 0 01-3.674 1.453 5.28 5.28 0 110-10.56 5.104 5.104 0 013.608 1.418l1.397-1.396a6.933 6.933 0 00-4.983-2.002 7.27 7.27 0 100 14.53 6.677 6.677 0 005.071-2.035 6.6 6.6 0 001.716-4.652 6.901 6.901 0 00-.099-1.233h-6.688zm49.841 1.54a4.4 4.4 0 00-4.004-2.98 4.4 4.4 0 00-4.4 4.675 4.653 4.653 0 008.536 2.607l-1.595-1.1a2.652 2.652 0 01-2.299 1.287 2.365 2.365 0 01-2.266-1.42l6.259-2.585-.231-.483zm-6.38 1.563a2.574 2.574 0 012.453-2.74 1.804 1.804 0 011.727 1.002l-4.18 1.737zM90.893 33h2.057V19.25h-2.057V33zm-3.366-8.03h-.077a3.235 3.235 0 00-2.464-1.1 4.687 4.687 0 000 9.36 3.168 3.168 0 002.464-1.1h.077v.672c0 1.793-.957 2.75-2.497 2.75a2.595 2.595 0 01-2.365-1.661l-1.782.736a4.399 4.399 0 004.147 2.773c2.409 0 4.4-1.42 4.4-4.873V24.2h-1.903v.77zm-2.365 6.468a2.838 2.838 0 010-5.666 2.629 2.629 0 012.508 2.828 2.618 2.618 0 01-2.508 2.838zm26.829-12.189h-4.928V33h2.057v-5.213h2.871a4.278 4.278 0 100-8.537zm0 6.6h-2.871v-4.686h2.915a2.357 2.357 0 012.354 2.354 2.36 2.36 0 01-.69 1.665 2.351 2.351 0 01-1.664.69l-.044-.023zm12.694-1.969a3.838 3.838 0 00-3.663 2.102l1.815.758a1.96 1.96 0 011.881-1 1.977 1.977 0 011.971 1.024c.125.231.203.485.229.747v.131a4.6 4.6 0 00-2.145-.527c-1.958 0-3.96 1.1-3.96 3.102a3.17 3.17 0 001.07 2.237 3.185 3.185 0 002.351.788 2.916 2.916 0 002.618-1.343h.066V33h1.98v-5.29c0-2.41-1.826-3.806-4.169-3.806l-.044-.023zm-.253 7.535c-.671 0-1.606-.34-1.606-1.166 0-1.1 1.166-1.474 2.2-1.474a3.654 3.654 0 011.87.462 2.485 2.485 0 01-2.42 2.2l-.044-.021zm11.682-7.215l-2.354 5.962h-.066l-2.442-5.962h-2.2l3.663 8.337-2.09 4.632h2.145l5.632-12.97h-2.288zm-18.48 8.8h2.046V19.25h-2.046V33z"
      ></path>
      <path
        fill="url(#paint0_linear_296_11970)"
        d="M11.484 8.288a2.2 2.2 0 00-.517 1.54V34.16a2.134 2.134 0 00.517 1.54l.077.088 13.629-13.63v-.33L11.56 8.212l-.076.077z"
      ></path>
      <path
        fill="url(#paint1_linear_296_11970)"
        d="M29.7 26.705l-4.51-4.543v-.33l4.51-4.543.099.066 5.4 3.058c1.54.87 1.54 2.3 0 3.18l-5.378 3.057-.121.055z"
      ></path>
      <path
        fill="url(#paint2_linear_296_11970)"
        d="M29.832 26.634l-4.642-4.642-13.706 13.706a1.782 1.782 0 002.277.077l16.071-9.14z"
      ></path>
      <path
        fill="url(#paint3_linear_296_11970)"
        d="M29.832 17.356l-16.07-9.13a1.77 1.77 0 00-2.278.066L25.19 21.998l4.642-4.642z"
      ></path>
      <path
        fill="#000"
        d="M29.7 26.54l-15.939 9.074a1.826 1.826 0 01-2.2 0l-.077.077.077.088a1.827 1.827 0 002.2 0l16.071-9.14-.132-.1z"
        opacity="0.2"
      ></path>
      <path
        fill="#000"
        d="M11.484 35.551A2.2 2.2 0 0111 34v.165a2.134 2.134 0 00.517 1.54l.077-.077-.11-.077zM35.2 23.422l-5.5 3.113.099.1 5.401-3.059a1.925 1.925 0 001.166-1.584 2.047 2.047 0 01-1.166 1.43z"
        opacity="0.12"
      ></path>
      <path
        fill="#fff"
        d="M13.761 8.388L35.2 20.576a2.047 2.047 0 011.166 1.43 1.924 1.924 0 00-1.166-1.584L13.761 8.234c-1.529-.88-2.794-.154-2.794 1.606v.165c.033-1.76 1.265-2.486 2.794-1.617z"
        opacity="0.25"
      ></path>
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_296_11970"
        x1="23.98"
        x2="5.522"
        y1="9.575"
        y2="28.033"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00A0FF"></stop>
        <stop offset="0.01" stopColor="#00A1FF"></stop>
        <stop offset="0.26" stopColor="#00BEFF"></stop>
        <stop offset="0.51" stopColor="#00D2FF"></stop>
        <stop offset="0.76" stopColor="#00DFFF"></stop>
        <stop offset="1" stopColor="#00E3FF"></stop>
      </linearGradient>
      <linearGradient
        id="paint1_linear_296_11970"
        x1="37.213"
        x2="10.604"
        y1="21.997"
        y2="21.997"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFE000"></stop>
        <stop offset="0.41" stopColor="#FFBD00"></stop>
        <stop offset="0.78" stopColor="orange"></stop>
        <stop offset="1" stopColor="#FF9C00"></stop>
      </linearGradient>
      <linearGradient
        id="paint2_linear_296_11970"
        x1="27.313"
        x2="2.277"
        y1="24.522"
        y2="49.547"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF3A44"></stop>
        <stop offset="1" stopColor="#C31162"></stop>
      </linearGradient>
      <linearGradient
        id="paint3_linear_296_11970"
        x1="8.03"
        x2="19.206"
        y1="0.196"
        y2="11.372"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#32A071"></stop>
        <stop offset="0.07" stopColor="#2DA771"></stop>
        <stop offset="0.48" stopColor="#15CF74"></stop>
        <stop offset="0.8" stopColor="#06E775"></stop>
        <stop offset="1" stopColor="#00F076"></stop>
      </linearGradient>
      <clipPath id="clip0_296_11970">
        <path fill="#fff" d="M0 0H148.5V44H0z"></path>
      </clipPath>
    </defs>
  </svg>
);

export default GooglePlayIconDark;
