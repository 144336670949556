import { motion, SVGMotionProps } from 'framer-motion';
import React from 'react';

const Sparkle1 = (props: SVGMotionProps<SVGSVGElement>) => (
  <motion.svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="30"
    fill="none"
    viewBox="0 0 24 30"
    {...props}
  >
    <path
      fill="#E7C6C7"
      d="M23.51 13.828c-3.15-2.5-7.73-2.6-9.57-6.63-.62-1.38-.57-7.81-2.78-5.83-.75.68-.68 3.7-1 4.8a17.811 17.811 0 01-2.41 5.26 12.06 12.06 0 01-4.29 3.77c-1.07.57-4.27 1.18-3.26 2.89.39.65 3.9.16 4.74.33a10.72 10.72 0 014.75 2.31 10.29 10.29 0 013 4.26c.39 1 .19 3.93 1 4.52 1.91 1.41 1.42-2.75 1.42-3.44 0-2.17-.17-4.73 1-6.68a7.13 7.13 0 013.61-2.75c.49-.25 5.38-1.53 3.79-2.81z"
    ></path>
  </motion.svg>
);

export default Sparkle1;
