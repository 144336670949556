import { motion, SVGMotionProps } from 'framer-motion';
import React from 'react';

const Sparkle3 = (props: SVGMotionProps<SVGSVGElement>) => (
  <motion.svg
    xmlns="http://www.w3.org/2000/svg"
    width="31"
    height="36"
    fill="none"
    viewBox="0 0 31 36"
    {...props}
  >
    <path
      fill="#E7C6C7"
      d="M30.336 15.76c-2.24-2.05-5.72-1.66-8.37-2.81-5-2.19-4.51-6.6-5.7-11.1-.53-2-1.62-2.71-2.37-.44-.31.93.24 2 .19 2.91a9.42 9.42 0 01-1.39 4.21 13.47 13.47 0 01-6 5.12c-1.26.58-6.51 1.13-6.69 2.42-.22 1.57 5.41 2.94 6.48 3.5a12.23 12.23 0 016 6c1.07 2.54 0 6.46 1.19 8.67.4.75 1.09 1.91 2.07.73.81-1-.07-3.72 0-4.88a14.18 14.18 0 01.71-3.53 10.3 10.3 0 013.6-5.12 17.897 17.897 0 015.42-2.48c.85-.29 6.85-1.38 4.86-3.2z"
    ></path>
  </motion.svg>
);

export default Sparkle3;
